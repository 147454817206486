@import "styles/variables";

.not-have-subscription {
  font-size: 20px;
  color: #2990FB;
  text-align: center;
  margin-top: 50px;
}

.subscriptions-list {
  &__title {
    font-family: $Roboto;
    font-size: 20px;
    color: #555;
    margin-bottom: 10px;
  }
}