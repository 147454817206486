.sharing {
  position: relative;
}

.destinationTabs {
  display: flex;
  margin-bottom: 20px;
}

.destination {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-right: 16px;
  padding-bottom: 5px;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
  &:last-child {
    margin-right: 0;
  }
}

.isActiveDestination {
  color: #2990FB;
  pointer-events: none;
  border-bottom: 1px solid #2990FB;
}