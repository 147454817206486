@import 'styles/mixins';

.secretMenu {
  visibility: hidden;
  transform: translateX(100%);
  width: 280px;
  position: fixed;
  top: 60px;
  bottom: 0;
  right: 0;
  left: auto;
  background-color: #fff;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  @include transition(0.2s, ease-in-out, 0s, (visibility, transform));
}
.secretMenuIsOpened {
  visibility: visible;
  transform: translateY(0);
}

.closeButton {
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #D8D8D8;
  @include transition(0.2s, ease-in-out, 0s, (opacity));
  &:hover {
    opacity: .7;
  }
  > img {
    max-height: 25px;
  }
}

.content {
  padding: 20px;
  max-height: calc(100% - 40px);
  box-sizing: border-box;
  overflow: auto;
}

.sectionListItem {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.section {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}
.sectionTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.buttonActionText {
  margin-top: 5px;
  font-size: 12px;
  color: #555555;
  opacity: .8;
  line-height: 14px;
}

.key {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: uppercase;
}
.value {
  font-size: 12px;
}