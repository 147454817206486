.inputBox {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.textInput {
    padding: 0;
    width: 100%;
}

.inputWithLetter {
    padding-left: 26px !important;
}

.isError:not(:focus) {
    border-color: #ff5656 !important;
}

.letter {
    position: absolute;
    z-index: 3;
    font-size: 13px;
    line-height: 36px;
    top: 0;
    left: 8px;
    text-transform: uppercase;
}

.remove {
    margin-left: 20px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.isHideRemove {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.error {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 20px;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 14px;
    color: #ff5656;
    width: 130px;
}
