@import 'styles/fonts';

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.isFullScreenPreloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  max-width: 100%;
  width: 150px;
}

.text {
  font-family: $Roboto;
  font-size: 18px;
  color: #555;
  opacity: .8;
  padding-top: 10px;
}