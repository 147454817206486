.top-note {
  display: flex;
  justify-content: space-between;
  > div.note {
    display: flex;
    align-items: center;
    > span {
      font-size: 16px;
      padding-top: 2px;
    }
    > img {
      width: 22px;
      margin-right: 10px;
    }
  }
}
div.buttons-list {
  display: flex;
  justify-content: flex-end;
}