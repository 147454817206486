.audioButton {
  flex-shrink: 0;
  margin-left: 20px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #2990FB;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
}