@import 'styles/fonts';

li.box {
  width: 33%;
  margin-right: 20px;
  padding: 30px 40px;
  box-sizing: border-box;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  &:last-child {
    margin-right: 0;
  }
  > div.title {
    font-family: $Roboto;
    font-size: 20px;
    color: #555555;
    padding-bottom: 8px;
  }
  > div.note {
    font-family: $Roboto;
    font-size: 14px;
    color: #555555;
    opacity: 0.6;
    padding-bottom: 20px;
  }
  > ul.list {
    > li.item {
      display: flex;
      justify-content: space-between;
      font-family: $Roboto;
      font-size: 14px;
      color: #2990FB;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      > p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2px 0;
      }
      > span {
        flex-shrink: 0;
        color: #555555;
        padding-left: 10px;
      }
    }
  }
  > div.is-empty {
    font-family: $Roboto;
    font-size: 12px;
    color: #555555;
    opacity: 0.6;
    padding-top: 50px;
    text-align: center;
  }
  > div.show-more {
    text-align: center;
    margin-top: 15px;
    > span {
      color: #2990FB;
      cursor: pointer;
      font-size: 14px;
      transition: opacity .2s ease-in-out;
      &:hover {
        opacity: .7;
      }
    }
  }
}