.workplace {
  width: 100%;
  display: flex;
}

.box {
  width: 50%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  position: relative;
  &:first-child {
    padding-right: 20px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: rgba(0,0,0,.2);
    }
  }
}

.boxGroup {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}
.boxGroupTitle {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 5px;
}
.boxGroupList {
  position: relative;
}

.isDisabled {
  pointer-events: none;
  opacity: .5;
}

.topicsHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 20px;
  box-sizing: border-box;
}

.topicsHeadTitle {
  font-size: 12px;
  font-weight: bold;
}

.topicsHeadCounter {
  font-weight: bold;
}

.topicsListWrapper {
  position: absolute;
  top: 19px;
  right: 0;
  left: 20px;
  bottom: 98px;
  overflow: auto;
  padding: 10px 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.topicsList {
  position: relative;
}

.topic {
  width: 100%;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.topicBlock {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.topicBlockTitle {
  font-size: 10px;
  font-weight: bold;
}

.addTopic {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  padding-top: 15px;
}

.addTopicBlock {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.generateAllButton {
  width: 100%;
  flex-shrink: unset !important;
}

.generateOneButtonWrapper {
    flex-shrink: 0;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.boxInputAddTopic {
  margin-right: 10px;
}