@import "styles/variables";

.dataItem {
  width: 100%;
  white-space: nowrap;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-family: $Roboto;
  font-size: 12px;
  color: #555555;
  opacity: 0.6;
  margin-bottom: 5px;
}

.value {
  font-family: $Roboto;
  font-size: 12px;
  line-height: 14px;
  color: #555555;
  display: flex;
  align-items: center;
  > img {
    margin-left: 10px;
    height: 12px;
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  }
}