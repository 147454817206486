@import "styles/variables";

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
}

.isDisabledTopBar {
  opacity: .5;
  pointer-events: none;
}

.topic {
  width: 100%;
}

.topicInput {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}

.modeList {
  display: flex;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 20px;
  flex-shrink: 0;
}

.mode {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d2d2d2;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  &:last-child {
    border-right: 0;
  }
}

.isActiveMode {
  pointer-events: none;
  background-color: $primaryColor;
}