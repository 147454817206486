.formBlockInner {
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
}

.children {
  width: 100%;
}

.actions {
  display: flex;
  margin-left: 20px;
}

.action {
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
}

.splitActions {
  display: flex;
  flex-direction: column;
}

.actionItem {
  border-radius: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
}

.isSplitActionItem {
  height: 16px;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
}

.actionUpIcon {
  transform: rotate(90deg);
}

.actionDownIcon {
  transform: rotate(-90deg);
}