.generator {
  position: relative;
}

.box {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  position: relative;
  &:first-child {
    padding-right: 20px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: rgba(0,0,0,.2);
    }
  }
}

.topicBlock {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.topicBlockTitle {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.isDisabled {
  pointer-events: none;
  opacity: .5;
}

.boxInput {
  width: 100%;
}
.boxInputAddTopic {
  margin-right: 10px;
}

.splitBlock {
  width: 100%;
  display: flex;
}

.topicsListWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 20px;
  bottom: 60px;
  overflow: auto;
  padding-bottom: 10px;
}

.topicsList {
  position: relative;
}

.topic {
  width: 100%;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.addTopic {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  padding-top: 24px;
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.addTopicManually {
  display: flex;
  width: 100%;
}

.addTopicByGenerator {
  flex-shrink: 0;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.generationBlock {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.generationBlockTitle {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.projectsPreview {
  margin-top: 40px;
}

.generationProgress {
  margin-top: 40px;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}