.exchangeRateBox {
  display: flex;
  align-items: center;
}

.exchangeRate {
  margin-right: 16px;
  display: flex;
  flex-direction: column;
}

.exchangeRateValue {
  font-size: 14px;
}
.exchangeRateDate {
  font-size: 12px;
  margin-top: 4px;
  opacity: .6;
}
