.tabs {
  display: flex;
}

.tab {
  font-size: 12px;
  color: #555;
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
}

.isActiveTab {
  pointer-events: none;
  border-color: #2990FB;
}