.iframeWrapper {
    background-color: #F9FAFD;
    position: relative;
    max-width: 100%;
}

.isDesktop {
    background-color: transparent;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    .iframeInner {
        top: 0;
        left: 0;
        height: 100%;
        position: relative;
    }
}

.isMobile {
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    border: 44px solid #fff;
    border-left-width: 8px;
    border-right-width: 8px;
    border-radius: 20px;
    width: 322px;
    height: 570px;
    margin-bottom: 20px;
    background-color: #fff;
    &:before {
        content: '';
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translateX(-50%);
        width: 14px;
        height: 14px;
        background-color: #d8d8d8;
        border-radius: 50%;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 8px;
        background-color: #d8d8d8;
        border-radius: 12px;
    }
    .iframeInner {
        box-sizing: border-box;
        border: 1px solid #d8d8d8;
        flex: auto;
    }
}

.iframeInner {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
