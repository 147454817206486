.filters {

}

.filterBox {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-size: 14px;
  padding-bottom: 10px;
  color: #555;
  font-weight: bold;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.item {
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 4px;
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover {
    opacity: .7;
  }
}

.isActiveItem {
  border-color: #2990FB;
  background-color: #2990FB;
  color: #fff;
}