.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}