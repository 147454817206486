.compactView {
  font-size: 13px;
  color: #555;
  display: flex;
  height: 36px;
  align-items: center;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .7;
  }
}

.isDisabledCompactView {
  opacity: .4;
  pointer-events: none;
}