@import 'styles/mixins';

.dashboard {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #F9FAFD;
  @include maxWidth(1580) {
    background-color: #fff;
  }
}
.dashboardWorkplace{
  height: 100%;
  display: flex;
  overflow: hidden;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  box-sizing: border-box;
  padding-bottom: 40px;
}

.contentWorkplace {
  margin: 20px;
  background-color: #FFF;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  padding: 30px 20px 20px;
  border-radius: 8px;
  @include maxWidth(1580) {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }
}