.image {
  width: 100%;
  max-width: 512px;
  position: relative;
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  font-size: 0;
  min-height: 200px;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.imageActions {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.7);
  opacity: 0;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: 1;
  }
}

.refreshButton {
  margin-right: 10px;
}

.customUploadInput {
  display: none;
}

.imageElement {
  width: 100%;
}