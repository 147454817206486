.tabs {
  display: flex;
  margin-top: 20px;
}

.tab {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
}

.isActiveTab {
  color: #2990FB;
  pointer-events: none;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    margin-top: 2px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #2990FB;
  }
}