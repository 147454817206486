.common__breadcrumbs {
  width: 100%;
  padding: 10px 0;
  > ul {
    display: flex;
    > li {
      position: relative;
      display: flex;
      align-items: center;
      &:not(:last-child):after {
        content: '\00a0/\00a0';
        font-size: 20px;
      }
      &:last-child {
        > .label {
          opacity: .7;
        }
      }
      > .label {
        font-size: 20px;
        > a {
          color: inherit;
        }
      }
      > ul.marks {
        display: flex;
        margin-top: 2px;
        > li.mark {
          margin-left: 5px;
          display: flex;
          align-items: center;
          font-size: 11px;
          height: 18px;
          padding: 0 6px;
          border-radius: 2px;
          cursor: help;
          letter-spacing: 0.5px;
          &.is-clickable {
            cursor: pointer;
            transition: opacity .2s ease-in-out;
            &:hover {
              opacity: .8;
            }
          }
        }
      }
    }
  }
}