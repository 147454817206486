.error-box {
  display: flex;
  &__code {
    font-size: 40px;
    margin-right: 10px;
  }
  &__content {
    &__text {
      font-size: 18px;
      margin-bottom: 5px;
    }
    &__link {
      > a {
        text-decoration: underline;
        color: #2990FB;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}