@import 'styles/fonts';

.defaultChart {
  width: 100%;
}
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectList {
  display: flex;
}
.periodSelect {
  width: 100px;
  margin-right: 10px;
}
.countSelect {
  width: 60px;
}
.buttonSelect {
  margin-left: 20px;
}
.defaultChartTitle {
  font-family: $Roboto;
  font-size: 20px;
  color: #555;
}
.defaultChartContent {
  margin-top: 12px;
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}