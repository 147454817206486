.marks {
  position: absolute;
  top: 4px;
  left: 4px;
  display: flex;
}

.mark {
  border-radius: 4px;
  font-size: 10px;
  cursor: help;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
}

.isSystemMark {
  background-color: #FFCC00;
  color: #000;
}

.isInterestsMark {
  background-color: #65bb5a;
  color: #fff;
}