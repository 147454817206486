.divider {
  position: relative;
  height: 1px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    left: -20px;
    right: -20px;
    bottom: 0;
    height: 100%;
    background-color: #D8D8D8;
  }
}
ul.top-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > li {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}