@import "styles/variables";

.projectsMenu {
  width: 300px;
  flex-shrink: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.list {
  position: relative;
}

.item {
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.label {
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
}

.isActiveLabel {
  pointer-events: none;
  color: $primaryColor;
}

.settingsList {
  margin-top: 10px;
}

.settingsItem {
  padding-bottom: 5px;
  &:last-child {
    padding-bottom: 0;
  }
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}