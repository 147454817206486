.blocks {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.item {
  margin-right: 10px;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 20px;
  border-radius: 4px;
  border: 1px solid #555;
  &:last-child {
    margin-right: 0;
  }
}

.isAddItem {
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
}

.actions {
  display: flex;
  align-items: center;
}
.action {
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  margin-left: 5px;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
  }
}

.dragPoint {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: grab;
}