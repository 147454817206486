@mixin transition($transition_duration, $transition_timing_function, $transition_delay, $transition_property) {
  transition-duration: $transition_duration;
  transition-timing-function: $transition_timing_function;
  transition-delay: $transition-delay;
  transition-property: $transition-property;
}

@mixin maxWidth($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}

@mixin minWidth($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin denySelection {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}