.translateButton {
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  flex-shrink: 0;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.isLoading {
  pointer-events: none;
  opacity: .5;
}