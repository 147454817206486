@import 'styles/fonts';

.current-subscription {
  &__title {
    display: flex;
    align-items: center;
    font-family: $Roboto;
    font-size: 20px;
    color: #555555;
    > img {
      max-height: 16px;
      margin-left: 15px;
      &.img-small {
        min-height: 22px;
      }
    }
  }
  &__buttons {
    display: flex;
    > button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  > h3 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > ul.data {
    display: flex;
    &.full-width-style {
      flex-direction: column;
      margin-top: 30px;
      > li {
        width: 100%;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        > ul {
          display: flex;
          width: 100%;
          overflow-x: auto;
          padding-bottom: 5px;
          > li {
            margin-right: 40px;
            > h5 {
              font-weight: bold;
              font-size: 10px;
              padding-bottom: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    > li {
      width: 20%;
      > h4 {
        font-family: $Roboto;
        font-size: 16px;
        color: #555555;
        margin-bottom: 15px;
      }
      > div.empty {
        font-family: $Roboto;
        font-size: 12px;
        color: #555555;
        opacity: 0.6;
      }
    }
  }
  .common__date-picker {
    width: 50%;
  }
}