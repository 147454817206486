div.layout__auth {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./i/auth-bg-4.jpg");
}