.common__checkbox {
  display: flex;
  padding: 1px;
  > div.box {
    cursor: pointer;
    background-color: #d7d7d7;
    width: 16px;
    height: 16px;
    position: relative;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
    margin-top: -1px;
    &.is-checked {
      background-color: #2990fb;
      background-image: url("./i/mark.svg");
    }
    &.is-disabled {
      pointer-events: none;
      opacity: .5;
    }
  }
  > p {
    margin-left: 10px;
    color: #6B6B6B;
  }
}