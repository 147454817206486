@import "styles/mixins";

.featuresModal {
  position: relative;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  width: 960px;
  max-width: 100%;
}

.head {
  text-align: left;
  font-weight: bold;
  font-size: 28px;
  padding: 0 30px 10px 10px;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: 25%;
  text-align: left;
  padding: 15px;
  box-sizing: border-box;
  @include maxWidth(1024) {
    width: 33.333%;
  }
  @include maxWidth(767) {
    width: 50%;
  }
}

.title {
  font-weight: bold;
  font-size: 10px;
  padding-bottom: 10px;
}

.rows {

}