@import 'styles/fonts';

.users-modal {
  position: relative;
  background-color: #fff;
  padding: 30px 40px;
  box-sizing: border-box;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  width: 600px;
  max-width: 100%;
  > div.close {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    outline: none;
    background-image: url("./i/cross.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: .7;
    }
  }
  > div.loader {
    margin-top: 10px;
    height: 10px;
    background-image: url("./i/loader.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  > div.title {
    font-family: $Roboto;
    font-size: 20px;
    color: #555555;
    padding-bottom: 8px;
  }
  > div.note {
    font-family: $Roboto;
    font-size: 14px;
    color: #555555;
    opacity: 0.6;
    padding-bottom: 20px;
  }
  > ul.list {
    > li.item {
      display: flex;
      justify-content: space-between;
      font-family: $Roboto;
      font-size: 14px;
      color: #2990FB;
      margin-bottom: 5px;
      border-bottom: 1px solid #555;
      padding-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
      }
      > p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 2px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      > div {
        text-align: right;
        flex-shrink: 0;
        color: #555555;
        padding-left: 10px;
        > div.date {
          margin-top: 3px;
          font-size: 10px;
        }
      }
    }
  }
  div.visibility-checker {
    opacity: 0;
    width: 100%;
    height: 1px;
  }
}