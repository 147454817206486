.projectViewer {
  max-width: 100%;
  width: 920px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 48px 60px 40px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 22px;
  height: 22px;
  background-image: url('./i/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
}

.content {
  position: relative;
}