.items {
  margin-bottom: 30px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.itemDivider {
  margin: 0 20px;
  font-size: 30px;
}

.buttonsBlock {
  margin-left: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}