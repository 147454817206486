.localeLabel {
  font-size: 10px;
  color: #555;
  opacity: .6;
}

.localeIcon {
  margin-right: 8px;
  height: 14px;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.localeInfo {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.localeWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlWrapper {
  width: 100%;
}