.emails-sending {
    &__header {
        margin-bottom: 35px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        color: #555555;
    }

    .divider {
        margin: 25px 0;
    }

    &__form {
        display: flex;
        flex-direction: column;
        h3 {
            font-size: 16px;
            margin-bottom: 20px;
        }
        &-filter {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -20px;
            > li {
                width: 50%;
                margin-bottom: 20px;
                box-sizing: border-box;
                display: flex;
                align-items: flex-end;
                > button {
                    flex-shrink: 0;
                    margin-left: 20px;
                    min-width: 90px;
                }
                &:nth-child(odd) {
                    padding-right: 10px;
                }
                &:nth-child(even) {
                    padding-left: 10px;
                }
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    &__status-list {
        margin-top: 30px;
        h3 {
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            color: #555555;
            margin-bottom: 15px;
        }
    }
    &__status-item {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: #555555;
        margin: 10px 15px;
    }
}
