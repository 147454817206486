.avatar {
  flex-shrink: 0;
  border-radius: 50%;
  font-weight: 500;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}