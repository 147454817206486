/* Fonts */
$Roboto: 'Roboto', sans-serif;
$Ubuntu: 'Ubuntu', sans-serif;

$successColor: #65bb5a;
$errorColor: #ff5656;
$primaryColor: #2990FB;
$secondaryColor: #555555;
$dangerColor: #FA655C;
$warningColor: #FFCC00;
$warningColor2: #dfad63;