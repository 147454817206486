.idBox {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  > a {
    font-weight: 700;
    font-size: 9px;
    color: #555;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: .8;
      text-decoration: none;
    }
  }
}

.arrowIcon {
  width: 8px;
  padding-right: 2px;
}

.copyIcon {
  width: 10px;
  padding-left: 4px;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
}