.interestsTags {
  position: relative;
}

.title {
  font-size: 20px;
  color: #555555;
  margin-bottom: 12px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.item {
  padding: 0 10px;
  height: 28px;
  margin: 5px;
  background-color: #fff;
  color: #2990FB;
  border-radius: 4px;
  border: 1px solid #2990FB;
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
}

.icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.label {
  font-size: 12px;
}

.tagsNotFound {
  font-size: 12px;
  font-weight: bold;
}