@import 'styles/fonts';

.auth-box {
  width: 428px;
  background-color: #FFF;
  border-radius: 4px;
  padding: 30px 80px 50px;
  box-sizing: border-box;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    position: relative;
    > img {
      max-width: 120px;
    }
    > p {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
      font-family: $Roboto;
      font-size: 11px;
    }
  }
  &__body {
    &__block {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__footer {
    margin-top: 30px;
    //display: flex;
    //justify-content: center;
  }
}