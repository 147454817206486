.actions {
  display: flex;
}

.action {
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
}

.button {
  padding: 0 !important;
  width: 28px !important;
  height: 28px !important;
}

.icon {
  width: 12px;
  height: 12px;
}