.inputBlock {
  width: 100%;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
}

.input {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.isColumnInput {
  flex-direction: column;
}