.step {
  position: relative;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.isDisabled {
  opacity: .5;
  pointer-events: none;
}

.head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0;
  border-right: 0;
  padding: 10px 20px;
}

.leftSide {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.rightSide {
  position: relative;
  flex-shrink: 0;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 42px;
  line-height: 42px;
  font-weight: bold;
  color: #2990FB;
  text-shadow: 1px 1px 2px #555;
}

.title {
  font-size: 28px;
  margin-left: 10px;
}

.icon {
  margin-left: 10px;
  width: 34px;
  height: 34px;
  > img {
    height: 100%;
  }
}

.content {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px 0 0 20px;
}

.isMinimizeContent {
  height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
}