.modal-wrapper {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    &__container {
        height: 100%;
        outline: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        &:after {
            content: '';
            width: 0;
            height: 100%;
            display: inline-block;
            vertical-align: middle;
        }
    }
    &__content {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        overflow-y: visible;
        padding: 20px;
        max-width: 100%;
        box-sizing: border-box;
        &__cross {
            cursor: pointer;
            position: absolute;
            z-index: 100;
            width: 20px;
            height: 20px;
            top: 30px;
            right: 30px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity .2s ease-in-out;
            > img {
               max-width: 100%;
                max-height: 100%;
            }
            &:hover {
                opacity: .7;
            }
        }
    }
}
