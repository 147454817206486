@import "styles/variables";

.progress {
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
}

.main {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}

.system {
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
}

.systemSuccess {
  color: $successColor;
}

.systemDivider {
  color: rgba(0, 0, 0, 0.2);
}

.systemFailed {
  color: $errorColor;
}