@import "styles/variables";

.formTabs {
  display: flex;
  padding-bottom: 4px;
}

.tabItem {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  font-size: 10px;
  color: #6B6B6B;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
  &:last-child {
    margin-right: 0;
  }
}

.tabItemRequired {
  &:after {
    content: '*';
    position: absolute;
    top: 0;
    right: -4px;
    color: $dangerColor;
    font-size: 8px;
  }
}

.tabItemActive {
  color: #2990FB !important;
  pointer-events: none;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid $primaryColor;
  }
}

.tabItemEmpty {
  color: $warningColor2;
}

.tabItemError {
  color: $dangerColor;
  border-color: $dangerColor;
}

