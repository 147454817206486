@import 'styles/fonts';
@import 'styles/mixins';

.header {
  background-color: #777980;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 10;
  box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
  position: relative;
  &--left {
    display: flex;
    align-items: center;
  }
  &--right {}
  &__logo {
    margin-right: 30px;
    @include maxWidth(1340) {
      margin-right: 20px;
    }
    > a {
      position: relative;
      &:after {
        content: 'Admin panel';
        position: absolute;
        color: #fff;
        font-size: 10px;
        bottom: -8px;
        right: 18px;
      }
      > img {
        @include maxWidth(1340) {
          max-width: 120px;
        }
      }
    }
  }
  &__nav {
    display: none;
    @include maxWidth(1580) {
      display: block;
    }
    @include maxWidth(1280) {
      display: none;
    }
    &__list {
      display: flex;
      > li {
        margin-right: 10px;
        @include maxWidth(1340) {
          margin-right: 8px;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: #fff;
          display: block;
          font-size: 13px;
          padding: 4px;
          border-radius: 4px;
          text-decoration: none;
          @include maxWidth(1580) {
            font-size: 12px;
          }
          &.active, &:hover {
            background-color: #2990FB;
          }
          &.active {
            pointer-events: none;
          }
        }
      }
    }
  }
  &__profile {
    position: relative;
    display: flex;
    align-items: center;
    &__info {
      display: flex;
      align-items: center;
      > div {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        background-color: #2990FB;
        border-radius: 50%;
        margin-right: 12px;
        font-size: 19px;
        font-weight: 500;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        @include denySelection();
        @include maxWidth(1340) {
          font-size: 16px;
          height: 36px;
          width: 36px;
          margin-right: 10px;
        }
      }
      > p {
        font-size: 16px;
        color: #FFF;
        @include maxWidth(1340) {
          font-size: 14px;
        }
      }
      > img {
        margin-left: 16px;
        width: 20px;
        transition: opacity .2s ease-in-out;
        cursor: pointer;
        &:hover {
          opacity: .7;
        }
        @include maxWidth(1340) {
          margin-left: 12px;
          width: 18px;
        }
      }
    }
  }
}