.box {
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  padding: 10px 5px;
  margin: 0 5px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  > img {
    width: 30px;
    padding-right: 10px;
  }
}

.content {
  position: relative;
}

.descriptionTitle {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.descriptionEditArea {
  border: 1px solid #D8D8D8;
  border-radius: 4px;
}