.orderNumber {
  display: flex;
  align-items: center;
  > img {
    margin-left: 8px;
    cursor: pointer;
    width: 18px;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: .8;
    }
  }
}