.editPlayground {
    width: 960px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    padding: 0 0 40px;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.closeIcon {
    position: absolute;
    z-index: 3;
    top: 40px;
    right: 40px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.addQuestionButton {
    padding: 0 150px 0 84px;
}

.addQuestionButtonMargin {
    margin-top: 30px;
}
