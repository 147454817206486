@import 'styles/fonts';
@import 'styles/mixins';

.common-pagination {
  margin-top: 20px;
  display: block;
  &.is-loading {
    opacity: 0.7;
    pointer-events: none;
  }
  ul.pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > li {
      outline: none;
      border-radius: 2px;
      background-color: #f9fafd;
      margin: 0 2px;
      @include transition(0.2s, ease-in-out, 0s, (background-color, box-shadow));
      &:hover:not(.is-active) {
        background-color: darken(#f9fafd, 2);
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      > a {
        font-family: $Roboto;
        display: flex;
        min-width: 34px;
        height: 34px;
        align-items: center;
        justify-content: center;
        color: #6c6c6c;
        font-size: 14px;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        padding: 0 5px;
        box-sizing: border-box;
      }
      &.is-active {
        background-color: #2990fb;
        > a {
          color: #fff;
          cursor: default;
        }
      }
      &.disabled {
        > a {
          cursor: default;
          color: #d8d8d8;
        }
      }
      &.previous,
      &.next {
        background-color: #f9fafd;
        background-repeat: no-repeat;
        background-position: center;
        > a {
          font-size: 0;
        }
      }
      &.previous {
        background-image: url('./i/arrow-left.svg');
        margin-right: 8px;
        &.disabled {
          visibility: hidden;
        }
      }
      &.next {
        background-image: url('./i/arrow-right.svg');
        margin-left: 8px;
        &.disabled {
          visibility: hidden;
        }
      }

      &.break {
        background-color: #fff;
      }
    }
  }
}
