@import 'styles/variables';
@import 'styles/mixins';

.tags {
  &__list {
    flex-wrap: wrap;
    display: flex;
    > li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #D8D8D8;
      border-top: 0;
      border-left: 0;
      padding: 12px 10px;
      position: relative;
      box-sizing: border-box;
      flex: 0 0 25%;
      &:nth-child(-n+4) {
        border-top: 1px solid #D8D8D8;
      }
      &:nth-child(4n + 1) {
        border-left: 1px solid #D8D8D8;
      }
      @include maxWidth(1720) {
        flex: 0 0 33.333%;
        &:nth-child(-n+4) {
          border-top: 0;
        }
        &:nth-child(4n + 1) {
          border-left: 0;
        }
        &:nth-child(-n+3) {
          border-top: 1px solid #D8D8D8;
        }
        &:nth-child(3n + 1) {
          border-left: 1px solid #D8D8D8;
        }
      }
      &:hover {
        > div.name {
          opacity: .7;
        }
      }
      > div.system {
        position: absolute;
        top: 2px;
        left: 2px;
        background-color: #FFCC00;
        border-radius: 20px;
        color: #000;
        font-size: 11px;
        cursor: help;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > div.name {
        font-size: 14px;
        transition: opacity .2s ease-in-out;
      }
      > ul.actions {
        display: flex;
        > li {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  &__empty {
    font-family: $Roboto;
    font-size: 12px;
    padding: 30px 0;
    text-align: center;
    color: #8A8A8A;
  }
}