.common__toggle {
  display: flex;
  align-items: center;
  padding: 1px;
  > p {
    margin-left: 10px;
    color: #6B6B6B;
  }
  > label {
    display: flex;
    background-color: #D8D8D8;
    width: 32px;
    height: 16px;
    border-radius: 8px;
    position: relative;
    flex-shrink: 0;
    &.is-checked {
      background-color: #2990FB;
      > span {
        border-color: #989898;
        left: auto;
        right: 0;
      }
    }
    &.is-disabled,
    &.is-loading {
      opacity: .5;
    }
    > input {
      border: 0;
      margin: 0;
      position: absolute;
      cursor: pointer;
      z-index: 5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      &:disabled {
        cursor: not-allowed;
      }
    }
    > span {
      position: absolute;
      width: 18px;
      height: 18px;
      top: -1px;
      background: #FFFFFF;
      border: 1px solid #989898;
      box-sizing: border-box;
      border-radius: 50%;
      left: 0;
      right: auto;
    }
  }
}