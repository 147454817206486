.common__color {
  position: relative;
  &__input-box {
    display: flex;
    > div.common__input {
      width: 100%;
    }
    > div.preview {
      border: 1px solid #D8D8D8;
      flex-shrink: 0;
      margin-right: 12px;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      box-sizing: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 280px;
    }
  }
  &__picker {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 2px;
    z-index: 2;
    display: block
  }
}