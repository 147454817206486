ul.user-info-blocks {
  > li {
    margin-bottom: 30px;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
    > h3 {
      font-size: 16px;
      margin-bottom: 20px;
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;
      > li {
        width: 50%;
        margin-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        > button {
          flex-shrink: 0;
          margin-left: 20px;
          min-width: 90px;
        }
        &:nth-child(odd) {
          padding-right: 10px;
        }
        &:nth-child(even) {
          padding-left: 10px;
        }
      }
    }
  }
}