.progress {
  background-color: #fff;
  box-shadow: 0 2px 9px rgba(0,0,0,.07);
  border-radius: 4px;
  padding: 20px;
}

.info {
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 14px;
  font-weight: bold;
  color: #8d8d8d;
}
.values {
  font-size: 14px;
  color: #8a8a8a;
}

.line {
  margin-top: 8px;
  width: 100%;
  height: 16px;
  display: block;
  border-radius: 2px;
  background-color: #efeeee;
  position: relative;
  overflow: hidden;
}
.lineFiller {
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: #2990fb;
}

.isDanger {
  background-color: #ff5656;
}