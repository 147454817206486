.common__select {
  position: relative;
  width: 100%;
  &.is-disabled {
    opacity: .4;
    pointer-events: none;
  }
  > p.error, p.notice {
    position: absolute;
    font-size: 11px;
    top: 100%;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 2px 0;
  }
  > p.error {
    text-align: left;
    color: #FA655C;
  }
  > p.notice {
    text-align: right;
  }
}