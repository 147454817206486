@import "styles/mixins";

.hamburgerMenu {
  display: none;
  @include maxWidth(1280) {
    display: block;
  }
}

.hamburgerListRoot {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #777980;
  display: flex;
}

.hamburgerListScrollContainer {
  margin: auto;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  padding: 60px 50px;
  box-sizing: border-box;
}

.icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .2s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  &:hover {
    opacity: .8;
  }
}

.hamburgerIcon {
  margin-left: 20px;
  background-image: url("./i/hamburger.png");
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 15px;
  background-image: url("./i/close.png");
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  color: #fff;
  display: block;
  font-size: 18px;
  padding: 4px;
  border-radius: 4px;
  text-decoration: none !important;
  &:hover {
    background-color: #2990FB;
  }
}

.activeLink {
  background-color: #2990FB;
  pointer-events: none;
}