@import "styles/variables";

.mdxeditor-popup-container {
  z-index: 99999;
}

.mdxeditor-toolbar {
  border-radius: 4px 4px 0 0;
  border: 1px solid #D8D8D8;
}

.mdxeditor-root-contenteditable {
  border: 1px solid #D8D8D8;
  border-radius: 0 0 4px 4px;
  border-top: 0;

  // For correct view
  > div {
    padding: 10px 5px;
    font-family: $Ubuntu;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
  }
  h1 {
    font-size: 36px;
    line-height: 48px;
    font-weight: bold;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    font-size: 20px;
    line-height: 32px;
  }
  hr {
    margin: 32px 0;
    border: 1px solid #D8D8D8;
  }
  img {
    max-width: 100%;
  }
  ul, ol {
    display: flex;
    flex-direction: column;
    padding-inline-start: 24px;
    > li {
      margin: 0;
    }
  }
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
}