@import 'styles/fonts';
@import 'styles/variables';

$opacityOnHover: .8;

button.common__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 36px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  background-color: #FFF;
  overflow: hidden;
  text-decoration: none;
  font-family: $Roboto;
  font-size: 13px;
  position: relative;
  white-space: pre-wrap;
  flex-shrink: 0;
  > div.loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > p {
      font-size: 10px;
      padding-bottom: 3px;
      text-align: center;
      color: #fff;
    }
    > img {
      max-width: 24px;
    }
  }

  &.primary {
    color: $primaryColor;
    border-color: $primaryColor;
    > div.loader, &.is-inverse, &:hover {
      background-color: $primaryColor;
    }
  }
  &.secondary {
    color: $secondaryColor;
    border-color: $secondaryColor;
    > div.loader, &.is-inverse, &:hover {
      background-color: $secondaryColor;
    }
  }
  &.danger {
    color: $dangerColor;
    border-color: $dangerColor;
    > div.loader, &.is-inverse, &:hover {
      background-color: $dangerColor;
    }
  }

  &.is-inverse {
    color: #FFF;
    background-color: $primaryColor;
    &:hover {
      opacity: $opacityOnHover;
    }
  }
  &:hover {
    color: #FFF;
  }

  &.is-wide {
    width: 100%;
  }

  &:disabled {
    user-select: none;
    pointer-events: none;
    &:not(.is-loading) {
      opacity: .5;
    }
  }
}