.wrapper {
  position: relative;
}

.content {
  position: relative;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  overflow: hidden;
}

.isErrorContent {
  border-color: #FA655C;
}

.error {
  color: #FA655C;
  position: absolute;
  font-size: 11px;
  top: 100%;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 2px 0;
  text-align: left;
}