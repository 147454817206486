.priceNote {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  color: #555;
}

.price {
  font-weight: bold;
}