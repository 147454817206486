@import 'styles/variables';
@import 'styles/mixins';

.folders {
  opacity: 1;
}

.folderList {
  flex-wrap: wrap;
  display: flex;
}

.filter {
  margin-bottom: 15px;
}

.title {
  font-size: 20px;
  color: #555;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.folder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D8D8D8;
  border-top: 0;
  border-left: 0;
  padding: 12px 10px;
  position: relative;
  box-sizing: border-box;
  flex: 0 0 25%;
  overflow: hidden;
  &:hover {
    .name {
      opacity: .7;
    }
  }
  &:nth-child(-n+4) {
    border-top: 1px solid #D8D8D8;
  }
  &:nth-child(4n + 1) {
    border-left: 1px solid #D8D8D8;
  }
  @include maxWidth(1720) {
    flex: 0 0 33.333%;
    &:nth-child(-n+4) {
      border-top: 0;
    }
    &:nth-child(4n + 1) {
      border-left: 0;
    }
    &:nth-child(-n+3) {
      border-top: 1px solid #D8D8D8;
    }
    &:nth-child(3n + 1) {
      border-left: 1px solid #D8D8D8;
    }
  }
}

.name {
  font-size: 14px;
  transition: opacity .2s ease-in-out;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  display: flex;
  flex-shrink: 0;
  margin-left: 20px;
  > li {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.emptyList {
  font-family: $Roboto;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
  color: #8A8A8A;
}