@import 'styles/fonts';
@import 'styles/variables';

.Toastify {
    &__toast.toast {
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        border: 1px solid #2990fb;
        overflow: hidden;
        background-color: #fff;
        padding: 16px 28px 16px 16px;
        &.Toastify__toast--success {
            border-color: $successColor;
        }
        &.Toastify__toast--error {
            border-color: $errorColor;
        }
        &:last-child {
            margin-bottom: 0;
        }
        > div.toast-body {
            padding: 0;
            background-image: none;
            min-height: auto;
            display: block;
            .toast-box {
                display: flex;
                &__icon {
                    font-size: 0;
                    margin-right: 20px;
                    flex-shrink: 0;
                }
                &__content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    &--centered {
                        justify-content: center;
                    }
                }
                &__title {
                    font-family: $Roboto;
                    color: #3c3c3c;
                    font-weight: 500;
                    &--with-offset {
                        margin-bottom: 5px;
                    }
                }
                &__message {
                    font-family: $Roboto;
                    color: #979797;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
        > button {
            font-size: 0;
            width: 12px;
            height: 12px;
            background-image: url('./i/close.svg');
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 10px;
            right: 10px;
        }
        > div.toast-progress {
            height: 3px;
            opacity: 1;
            &.Toastify__progress-bar--success {
                background-color: $successColor;
            }
            &.Toastify__progress-bar--error {
                background-color: $errorColor;
            }
        }
    }
}
