.pageTopbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 40px;
}

.pageTitle {
  font-size: 20px;
  display: flex;
  align-items: center;
}

.pageActions {
  font-size: 20px;
  display: flex;
  position: relative;
  > * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}