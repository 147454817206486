@import 'styles/variables';
@import 'styles/mixins';

.tagsList {
  flex-wrap: wrap;
  display: flex;
  margin: -10px;
}

.tag {
  position: relative;
  box-sizing: border-box;
  flex: 0 0 25%;
  padding: 10px;
  @include maxWidth(1720) {
    flex: 0 0 33.333%;
  }
}

.tagContent {
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
  position: relative;
  border-radius: 4px;
  padding: 0 10px;
  overflow: hidden;
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 54px;
  &:hover {
    .mainName {
      opacity: .7;
    }
  }
}

.info {
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 28px;
}

.mainName {
  font-size: 14px;
  transition: opacity .2s ease-in-out;
}

.icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
}

.children {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
    height: 1px;
    background-color: #D8D8D8;
  }
}

.childrenTagsList {
  position: relative;
  padding-left: 20px;
}

.childrenTag {
  position: relative;
  border-top: 1px solid #D8D8D8;
  &:first-child {
    border-top: 0;
  }
}