.top-bar {
  display: flex;
  &--left {
    width: 100%;
  }
  &--right {
    flex-shrink: 0;
    margin-left: 50px;
  }
}

.divider {
  position: relative;
  height: 1px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    left: -20px;
    right: -20px;
    bottom: 0;
    height: 100%;
    background-color: #D8D8D8;
  }
}