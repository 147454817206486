.users-counter {
  ul {
    display: flex;
    justify-content: center;
    > li {
      margin-left: 10px;
      border-left: 1px solid #D8D8D8;
      padding-left: 10px;
      font-size: 14px;
      position: relative;
      &:first-child {
        margin-left: 0;
        padding-left: 0;
        border-left: 0;
      }
      &:last-child {
        border-left: 0;
        padding-left: 0;
        &:before {
          content: '/';
          color: #D8D8D8;
          padding-right: 10px;
        }
      }
      > span {
        font-weight: bold;
      }
    }
  }
}