body {
  font-family: $Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #555;
}

a {
  color: #2990FB;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

b {
  font-weight: bold;
}
i {
  font-style: italic;
}
