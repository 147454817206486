@import 'styles/fonts';

$colRightOffset: 10px;

.common__table {
  &--scrollable-wrapper {
    overflow-x: auto;
  }
  &__info {
    position: relative;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    &__title {
      font-family: $Roboto;
      font-size: 20px;
      color: #555555;
      display: flex;
      align-items: baseline;
    }
    &__counter {
      font-family: $Roboto;
      font-size: 12px;
      color: #555555;
      opacity: 0.6;
    }
  }

  &__header {
    &__row {
      display: flex;
      padding: 15px;
      border: 1px solid #D8D8D8;
      background-color: #f9f9f9;
    }
    &__col {
      display: flex;
      flex-direction: column;
      padding-right: $colRightOffset;
      box-sizing: border-box;
      justify-content: space-between;
      &:last-child {
        padding-right: 0;
      }
      &__label {
        display: flex;
        align-items: center;
        opacity: .6;
        transition: .2s opacity ease-in-out;
        &.with-sort-param {
          cursor: pointer;
          &:hover {
            opacity: .8;
          }
        }
        > p {
          font-family: $Roboto;
          font-size: 12px;
          color: #555555;
        }
        > img {
          width: 8px;
          margin-left: 6px;
        }
      }
      &__filter {
        margin-top: 10px;
      }
    }
  }
  &__child-content {
    &__row {}
  }
  &__content {
    &.is-loading {
      opacity: .7;
      pointer-events: none;
    }
    > a {
      text-decoration: none;
    }
    &__loading, &__empty {
      font-family: $Roboto;
      font-size: 12px;
      padding: 30px 0;
      text-align: center;
      color: #8A8A8A;
    }
    &__row-wrapper {
      position: relative;
      border: 1px solid #D8D8D8;
      border-top: 0;
      &__is-show-children {
        &:before {
          content: '';
          position: absolute;
          z-index: 10;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          pointer-events: none;
          border: 1px solid #2990FB;
        }
      }
    }
    &__row {
      display: flex;
      padding: 14px 15px;
      position: relative;
      box-sizing: border-box;
      &:hover {
        background-color: #F9FAFD;
        .common__table__content__col__value:after {
          background: linear-gradient(90deg, rgba(249, 250, 253, 0) 0%, rgba(249, 250, 253, .5) 50%, rgba(249, 250, 253, 1) 100%);
        }
      }
      &:before {
        content: attr(data-row-number);
        position: absolute;
        left: 2px;
        top: 2px;
        font-family: $Roboto;
        font-size: 10px;
        color: #555555;
        opacity: 0.6;
      }
      &.is-has-marks {
        padding-bottom: 36px;
        &:after {
          content: '';
          position: absolute;
          bottom: 22px;
          left: 0;
          right: 0;
          width: 100%;
          border-top: dashed 1px #D8D8D8;
          opacity: .7;
        }
      }
      &.is-children {
        border-top: 1px solid #D8D8D8;
      }
      &--children {
        &__button {
          display: flex;
          align-items: center;
          justify-content: right;
          color: #2990FB;
          font-weight: bold;
          cursor: pointer;
          transition: opacity .2s ease-in-out;
          padding: 4px 15px;
          margin-bottom: 4px;
          &:hover {
            opacity: .8;
          }
          &--is-active {
            > svg {
              transform: rotate(180deg);
              padding-left: 0;
              padding-right: 4px;
            }
          }
          > svg {
            padding-left: 4px;
          }
        }
      }
      a {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
    }
    &__col {
      position: relative;
      padding-right: $colRightOffset;
      box-sizing: border-box;
      display: flex;
      &:last-child {
        padding-right: 0;
        > .common__table__content__col__value:after {
          display: none;
        }
      }
      &.is-disabled {
        pointer-events: none;
      }
      &__value {
        width: 100%;
        position: relative;
        font-family: $Roboto;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #555555;
        overflow: hidden;
        min-height: 20px;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 15px;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 50%, rgba(255,255,255,1) 100%);
        }
        > img {
          max-width: 72px;
          max-height: 54px;
        }
        > div.list {
          max-width: 100%;
          > span {
            cursor: pointer;
            font-family: $Roboto;
            font-size: 12px;
            color: #2990FB;
            transition: opacity .2s ease-in-out;
            &:hover {
              opacity: 0.7;
            }
          }
        }
        > ul.icon-actions {
          display: flex;
          align-items: center;
          > li {
            display: flex;
            align-items: center;
            margin-right: 5px;
            cursor: pointer;
            transition: opacity .2s ease-in-out;
            &.is-disabled {
              cursor: not-allowed;
              pointer-events: none;
              opacity: .4;
            }
            &.left-offset {
              margin-left: 12px;
            }
            &.right-offset {
              margin-right: 12px;
            }
            &:last-child {
              margin-right: 0 !important;
            }
            &:hover {
              opacity: .7;
            }
            > a {
              display: flex;
              align-items: center;
            }
            img {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
      &__marks {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 18px;
        display: flex;
        align-items: center;
        > ul {
          display: flex;
          align-items: center;
          &.with-left-border {
            margin-left: 4px;
            padding-left: 4px;
            border-left: 1px solid #D8D8D8;
          }
          > li {
            font-size: 0;
            margin-right: 4px;
            cursor: help;
            > img {
              max-width: 14px;
              max-height: 14px;
            }
            &:last-child {
              margin-right: 0;
            }
            &.is-clickable {
              cursor: pointer;
              transition: opacity .2s ease-in-out;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
      &.is-clickable {
        cursor: pointer;
      }
    }
  }
  &__show-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    > span {
      font-family: $Roboto;
      font-size: 14px;
      color: #2990FB;
      cursor: pointer;
      transition: opacity .2s ease-in-out;
      &:hover {
        opacity: 0.7;
      }
      &.is-disabled {
        opacity: 0.7;
        pointer-events: none;
      }
    }
  }

  &__loader {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 15px 0 0 0;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-sizing: content-box;
    box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}