.items {
  margin-bottom: 30px;
}

.item {
  position: relative;
  margin-bottom: 30px;
  padding: 20px;
  border: 1px dashed #D8D8D8;
  width: 100%;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }
}

.questionMain {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.resultMain {
  display: block;
}

.answers {
  position: relative;
  margin-bottom: 10px;
}

.answer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.isCorrectAnswer {
  border-color: #65bb5a !important;
}

.answerNumber {
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.buttonsBlock {
  margin-left: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.buttonsCheckbox {
  margin-right: 10px;
}

.section {
  margin-bottom: 30px;
}

.sectionTitle {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}