.subscriptionBlock {
  margin-bottom: 15px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
}

.head {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  position: relative;
  transition: opacity .2s ease-in-out;
  font-size: 18px;
  padding-right: 60px;
  &:hover {
    opacity: .6;
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
    width: 20px;
    height: 20px;
    background-image: url("./i/arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transform: rotate(90deg);
  }
}

.isVisibleHead {
  &:after {
    transform: rotate(270deg);
  }
}

.subscriptionDetails {
  margin: 10px 0 20px;
}

.headActions {
  display: flex;
  align-items: center;
}

.iconCopy {
  margin-left: 5px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.projectLink {
  text-decoration: none !important;
}