@import 'styles/fonts';

.confirmation-dialog {
    max-width: 100%;
    width: 540px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    padding: 48px 60px 40px;
    box-sizing: border-box;
    text-align: left;
    position: relative;
    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 22px;
        height: 22px;
        background-image: url('./i/cross.svg');
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
    }
    &__success {
        padding-top: 55px;
        position: relative;
        text-align: center;
        font-family: $Roboto;
        font-size: 16px;
        line-height: 20px;
        white-space: pre-wrap;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-image: url("./i/success.svg");
            background-size: contain;
            background-position: center;
        }
    }
    &__head {
        font-family: $Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #3c3c3c;
    }
    &__image {
        text-align: center;
        padding: 20px 0 8px;
    }
    &__note {
        margin-top: 12px;
        font-family: $Roboto;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #3c3c3c;
        white-space: pre-line;
    }
    &__target {
        margin-top: 8px;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #3c3c3c;
        white-space: pre-line;
        font-weight: bold;
    }
    &__help {
        margin-top: 10px;
        color: #000;
        opacity: 0.7;
        font-size: 11px;
        text-align: left;
    }
    &__ensure {
        margin-top: 20px;
    }
    &__buttons {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        > button {
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
