.header {
    padding: 28px 60px 23px;
    border-bottom: 1px solid #d8d8d8;
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.texts {
    opacity: 1;
}

.actions {
    display: flex;
    align-items: center;
}

.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.number {
    font-size: 14px;
    line-height: 16px;
    color: #979797;
}

.notice {
    font-size: 14px;
    color: #3cba6e;
    margin-right: 24px;
}
.noticeError {
    color: #ff5656;
}

.name {
    margin-top: 20px;
}

.textInput {
    padding: 0 0 5px;
}
