.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -6px;
}

.isDisabledTags {
  opacity: .4;
  pointer-events: none;
}

.tag {
  margin: 0 6px 6px 0;
  padding: 0 8px;
  height: 28px;
  font-size: 12px;
  background-color: #D8D8D8;
  color: #000;
  border-radius: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
  border: 1px solid #2990FB;
  box-sizing: border-box;
}

.isMarkedTag {
  padding-top: 4px;
}

.isEditTag {
  background-color: #2990FB;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  padding: 0;
  width: 28px;
  justify-content: center;
  &:hover {
    opacity: .7;
  }
  > img {
    max-width: 12px;
    max-height: 12px;
  }
}

.marksList {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-bottom: 1px solid #2990FB;
  box-sizing: border-box;
}

.mark {
  height: 100%;
  width: 100%;
  cursor: help;
}
.isSystemMark {
  background-color: #FFCC00;
}
.isInterestsMark {
  background-color: #65bb5a;
}

.tagAction {
  display: flex;
  margin-left: 4px;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .7;
  }
  > img {
    max-width: 14px;
    max-height: 14px;
  }
}

.isEmptyList {
  font-size: 12px;
  color: #555;
  opacity: .6;
  height: 24px;
  display: flex;
  align-items: center;
}