.priceBlock {
  position: relative;
}

.main {
  display: flex;
  align-items: center;
}

.editIcon {
  width: 14px;
  height: 14px;
  margin-left: 8px;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
}

.per {
  margin-top: 4px;
  font-size: 10px;
}

.perPrice {
  font-weight: bold;
}