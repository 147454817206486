.formBlock {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.title {
  color: #6B6B6B;
  padding-bottom: 8px;
}

.control {
  width: 100%;
}