@import 'styles/mixins';

aside {
  @include maxWidth(1580) {
    display: none;
  }
  .sidebar-nav {
    flex-shrink: 0;
    width: 180px;
    height: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    background-color: #FFF;
    box-shadow: rgba(0,0,0,.12) 0 2px 10px, rgba(0,0,0,.16) 0 2px 5px;
    &__list {
      > li {
        a {
          color: #555;
          padding: 15px;
          font-size: 16px;
          text-decoration: none;
          display: flex;
          align-items: center;
          &.active, &:hover {
            color: #2990FB;
          }
          &.active {
            background-color: #F9FAFD;
            pointer-events: none;
          }
          > img {
            max-width: 16px;
            max-height: 16px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}