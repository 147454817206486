.common__upload {
  display: flex;
  justify-content: space-between;
  > .image-preview {
    height: 36px;
    width: 36px;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    margin-right: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    &--text {
      border: 1px solid #D8D8D8;
      font-size: 12px;
      font-weight: bold;
    }
    &--music {
      background-color: #2990FB;
      background-size: 20px;
    }
    &--link {
      color: #2990FB;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }
  > .file {
    position: relative;
    width: 100%;
    > input[type='file'] {
      border: 0;
      outline: none;
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  > button {
    flex-shrink: 0;
    margin-left: 12px;
  }
}