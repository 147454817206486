@import 'styles/fonts';

.common__input {
  position: relative;
  font-size: 0;
  > input {
    appearance: none;
    font-family: $Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #555555;
    height: 36px;
    padding: 0 5px;
    width: 100%;
    max-width: 100%;
    outline: none;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    resize: none;
    border-radius: 4px;
    background-color: #fff;
    &::placeholder {
      color: #878787;
    }
    &:disabled {
      pointer-events: none;
      opacity: .4;
    }
    &:read-only {
      cursor: default;
    }
    &:hover:not(:read-only), &:focus:not(:read-only) {
      border-color: #B3B3B3
    }
    &.is-clearable {
      padding-right: 28px;
    }
    &.is-painted-background {
      background-color: #F1F1F1;
      border-color: #F1F1F1 !important;
      color: #A9A9A9;
    }
    &.is-error {
      border-color: #FA655C !important;
    }
  }
  > p.error, p.notice {
    position: absolute;
    font-size: 11px;
    top: 100%;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 2px 0;
    text-align: left;
  }
  > p.error {
    color: #FA655C;
  }
  > p.notice {
    text-align: right;
  }
  > span.clear {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    width: 12px;
    height: 12px;
    background-image: url("./i/cross.svg");
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: .7;
    }
  }
}