.common__tabs {
  padding: 10px 0;
  &__list {
    display: flex;
    position: relative;
    &.with-bottom-line {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 10px;
    }
  }
  &__item {
    display: flex;
    margin-right: 40px;
    &:last-child {
      padding-right: 0;
    }
    &--disabled {
      pointer-events: none;
      opacity: .5;
    }
    &__tag {
      margin-left: 5px;
      display: flex;
      align-items: center;
      font-size: 11px;
      height: 18px;
      padding: 0 6px;
      border-radius: 2px;
      letter-spacing: 0.5px;
      color: #fff;
      cursor: help;
    }
    > a {
      color: #555;
      cursor: pointer;
      font-size: 16px;
      text-decoration: none;
      &.is-active {
        color: #2990FB;
      }
    }
  }
}