.chatGPT {
  position: relative;
  max-width: 600px;
  width: 100%;
}

.isCompactChatGPT {
  display: flex;
  align-items: flex-end;
  max-width: 100%;
  .setup {
    width: 50%;
    padding-right: 20px;
  }
  .result {
    width: calc(50% - 20px);
    margin: 0 0 0 20px;
    height: 222px;
  }
}

.title {
  font-size: 20px;
  margin-bottom: 20px;
}

.setup {
  box-sizing: border-box;
}

.settings {
  width: 300px;
}

.playground {
  width: 100%;
  margin-top: 20px;
}

.promptBox {
  display: flex;
}

.sendButton {
  width: 80px;
  height: 80px;
  margin-left: 20px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: #2990FB;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
  > svg {
    width: 30px;
    height: 30px;
  }
}

.isDisabledSendButton {
  pointer-events: none;
  opacity: .4;
}

.result {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 300px;
  border: 1px solid #D8D8D8;
  background-color: #fff;
  border-radius: 4px;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
  }
}

.resultPreloader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
}