.topicBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 4px;
  &:hover {
    background-color: rgba(0,0,0,.05);
  }
}

.text {
  font-size: 14px;
  padding-right: 20px;
}

.actions {
  display: flex;
  align-items: center;
}

.action {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}