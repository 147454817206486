.common__date-picker, .common__date-range-picker {
  .react-datepicker {
    border: 0;
    &-wrapper {
      display: block;
      width: 100%;
    }
    &__header {
      border: 0;
      border-radius: 0;
    }
    &__navigation {
      &--prev {
        left: 10px;
      }
      &--next {
        right: 10px;
      }
    }
    &__today-button {
      border-radius: 0;
      border: 1px solid #aeaeae;
      border-top: 0;
    }
    &__month-container {
      border-radius: 0;
      border: 1px solid #aeaeae;
    }
    &__time-container {
      border-radius: 0;
      position: absolute;
      top: 0;
      right: auto;
      left: 100%;
      border: 1px solid #aeaeae;
      border-left: 0;
      .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.common__date-range-picker {
  display: flex;
  align-items: center;
  > span.delimer {
    flex-shrink: 0;
    padding: 0 4px;
  }
}