@import 'styles/fonts';

.formModal {
  max-width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 25px 60px 20px;
  box-sizing: border-box;
  text-align: left;
  position: relative;
}

.successText {
  padding: 55px 0 5px;
  position: relative;
  text-align: center;
  font-family: $Roboto;
  font-size: 16px;
  line-height: 20px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-image: url("./i/success.svg");
    background-size: contain;
    background-position: center;
  }
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  width: 15px;
  height: 15px;
  top: 30px;
  right: 29px;
  outline: none;
  background-image: url("./i/cross.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .7;
  }
}

.title {
  font-size: 24px;
  color: #000;
  padding-bottom: 20px;
  position: relative;
  letter-spacing: .6px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -60px;
    right: -60px;
    height: 1px;
    background-color: #D8D8D8;
  }
  > p {
    padding-top: 5px;
    font-size: 12px;
    color: #6B6B6B;
  }
}

.body {
  padding: 25px 0;
  :global {
    .formBlock {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.footer {
  padding-top: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -60px;
    right: -60px;
    height: 1px;
    background-color: #D8D8D8;
  }
}