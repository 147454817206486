.roleMarks {
  display: flex;
}

.isDisabledRoleMarks {
  opacity: .4;
  pointer-events: none;
}

.role {
  margin-right: 4px;
  width: 14px;
  height: 14px;
  &:last-child {
    margin-right: 0;
  }
}

.isClickableRole {
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
}

.iconBox {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    max-width: 100%;
    max-height: 100%;
  }
}