.blockWrapper {
  padding: 20px;
  border: 1px dashed #D8D8D8;
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }
}

.isHideBorders {
  padding: 0;
  border: 0;
}

.blockTitle {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.blockContent {
  position: relative;
}