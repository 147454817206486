@import 'styles/variables';

.paymentStatus {
  display: flex;
  align-items: center;
  > img {
    margin-left: 8px;
    cursor: pointer;
    width: 18px;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: .8;
    }
  }
}

.payoutsDisabled {
  font-size: 12px;
  margin-top: 4px;
  color: $dangerColor;
}

.exchangeRateNotSet {
  font-size: 12px;
  margin-top: 4px;
  color: $warningColor;
}