@import 'styles/variables';

.user-public-profile-info-blocks {
  &__banner {
    height: 140px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    margin-bottom: 30px;
  }
  &__tags {
    display: flex;
    &__item {
      height: 36px;
      color: #000;
      background-color: $warningColor;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding: 0 10px;
      border-radius: 4px;
    }
  }
  > li {
    margin-bottom: 30px;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0;
    }
    > h3 {
      font-size: 16px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;
      > li {
        width: 50%;
        margin-bottom: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        > button {
          flex-shrink: 0;
          margin-left: 20px;
          min-width: 90px;
        }
        &:nth-child(odd) {
          padding-right: 10px;
        }
        &:nth-child(even) {
          padding-left: 10px;
        }
      }
    }
  }
}