.products {
  position: relative;
}

.tables {
  position: relative;
}

.table {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}